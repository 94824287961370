<template>
  <div id="NewsList">
    <Navbar>
      <span> {{ $t('navBarMoomall.moomallNewsDetail') }}</span>
    </Navbar>
    <v-main>
      <BaseCardList
        :itemList="List"
        :isNewsList="isNewsList"
        isGoto="NewsDetail"
      />
      <scroll-loader
        :loader-method="loading"
        :loader-disable="disable"
        loader-color="#D60000"
      >
      </scroll-loader>
    </v-main>
  </div>
</template>

<script>
import NewsService from '@/services/NewsService'

export default {
  name: 'NewsList',
  data() {
    return {
      List: [],
      isNewsList: true,
      disable: false,
      page: 1,
      pageSize: 10,
      totalItems: 0,
    }
  },
  methods: {
    loading() {
      NewsService.getNews({ page: this.page++, limit: this.pageSize })
        .then(res => {
          if (res.status === 200) {
            this.List.push(...res.data.items)
            this.totalItems = res.data.meta.totalItems

            // Stop scroll loading...
            if (this.List.length === this.totalItems) {
              this.disable = true
            }
          }
        })
        .catch(err => {
          this.$swal
            .fire({
              icon: 'error',
              title: `${err.message}`,
              showDenyButton: false,
            })
            .then(result => {
              if (result.isConfirmed) {
                return
              }
            })
        })
    },
  },
}
</script>

<style scoped></style>
