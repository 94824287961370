import { apiServices } from '@/utils/request'

export default {
  getNews({ page, limit }) {
    return apiServices.get(`/news?page=${page}&limit=${limit}`)
  },
  getNew(id) {
    return apiServices.get('/news/' + id)
  },
  createNews(formData) {
    return apiServices.post('news', formData)
  },
  deleteNews(id) {
    return apiServices.delete(`/news/${id}`)
  },
  updateNews(id, formData) {
    return apiServices.put(`/news/${id}`, formData)
  },
}
